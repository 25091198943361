@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.title{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      padding-top: 70px;padding-bottom: 50px;
      font-family: 'Pacifico', cursive;
}
.title h1{
      font-family: 'Pacifico', cursive;
}
.title img{
      width: 80px;
      height: 80px;
      padding-right: 10px;
}
@media screen and (max-width: 767px) {
      .title{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            padding-top: 20px;padding-bottom: 10px;
            background-color: #00000014;
      }
      .title img{
            width: 20px;
            height: 20px;
            padding-right: 10px;
      }
}