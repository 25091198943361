/* Footer Styles */
.footer {
      position: relative;
      background-image: url('../../Assets/images/2.jpg');
      background-position: center;
      background-size: cover;
      object-fit: cover;
      border-radius: 20px;
      width: 95%;
      margin: auto;
      z-index: 98;
    }
    
    .overlay {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(32, 32, 32, 0.459);
      border-radius: 20px;
      z-index: 1;
    } 
    
    .FooterContainer {
      position: relative;
      display: flex;
      justify-content: space-around;
      z-index: 98;
      height: 175px;
      padding-bottom: 20px;
    }
    
    .SosialMidiaIcons {
      height: 100px;
      margin-top: 75px;
      display: flex;
      justify-content: center;
     
    }
    
    .SosialMidiaIcons a {
      text-decoration: none;
      padding: 10px;
      margin: 10px;
      border-radius: 50%;
    }
    
    .SosialMidiaIcons a i {
      font-size: 2em;
      opacity: 0.9;
      color: white;
   
    }
    
    .Footerlogo img {
      object-fit: cover;
      display: flex;
     
    }
    .FooterBottom{
      position: relative;
      color: white;
      z-index: 98;
      text-align: center;
      padding: 10px;
    }
    @media screen and (max-width: 767px) {
      .FooterContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 0px;
        height: 100px;
      }
      .SosialMidiaIcons a {
        text-decoration: none;
        padding: 5px;
        margin: 5px;
        border-radius: 50%;
      }
      .SosialMidiaIcons a i {
        font-size: 1.5em;
        opacity: 0.9;
        color: white;
     
      }
      .Footerlogo img {
        width: 150px;
        height: 50px;
        margin:0;
        display: flex;
       
      }
      .SosialMidiaIcons {
        height: 100px;
        margin-top:0;
        display: flex;
        justify-content: center;
    }
    }