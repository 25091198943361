
.Header {
     position: relative;
     width: 95%;
     height: 250px;
     margin: 40px auto;
     font-size: 13px;
     background-image: url("../../../Assets/images/49404-Juiciest-Hamburgers-Ever-mfs-052-4ee37878e27b4e409b54f2a8f7313f99.jpg") ;
     border-radius: 10px;
     z-index: 999;
     transition: opacity 0.5s ease; /* Transition ekleyin */
   }

   .Header .overlay{
    position: absolute;
    width: 100%;height: 100%;
    border-radius: 10px;
    background-color: rgba(189, 189, 189, 0.5);
    z-index: 2;
   }
   .Header ul {
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    z-index: 99;
   }
   .Header ul li {
     display: inline-block;
     margin: auto;
     color: rgb(51, 51, 51);
     font-size: 16px;
     font-weight: 500;
   }
   .Header .logo{
    position: relative;
    height:100%;
    width: 50%;
    margin: auto;
    text-align: center;
    z-index: 98;
   }
   .Header .logo img{
    position: absolute;
    top: -18%;
    left: 49%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    margin: auto;
    width: 400px;
    height: 250px;
   }
   .icons {
     margin-right: 6px;
   }
   


   @media screen and (max-width: 767px) {
    .Header{
      margin: 0;
      border-radius: 0;
      width:100%;
      height: 50vh;
      font-size: 13px;
      background-size: cover;
      background-image: url(../../../Assets/images/pexels-daniela-constantini-5591664.jpg);
    }
    .Header ul {
      display: none;
    }
    .Header .logo{
      height: 60%;
    }
    .Header .soonText{
      position: relative;
      color: rgb(255, 255, 255);
      text-align: center;
      z-index: 99;
    }
    .Header .soonText h1{
      padding: 5px;
      width: 50%;
      margin: auto;
      border-bottom: 1px solid #fff;
    }
    .Header .soonText p{
      padding: 5px;
    }
     .Header .logo img{
      top: 15%;
      width: 200px;
      height: 200px;
     }
     .Header .overlay{
      border-radius: 0;
      background-color: rgba(39, 39, 39, 0.473);
     }
     .Header ul li {
      color: rgb(255, 255, 255);
      font-size: 8px;
      font-weight: 600;
    }

  }