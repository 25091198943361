
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700;900&display=swap');



*{
      padding: 0;
      margin: 0;
  
      font-family: 'Montserrat', sans-serif;
}
 



body{
    
      
}
