


.menu-section {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    
    .brand-card {
     
      width: 100%; /* Kartların genişliği ayarlanabilir */
      height: 200px; /* İstediğiniz yüksekliğe göre ayarlayabilirsiniz */ 
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 23px;
      overflow: hidden;

    }
    .brand-card>div>div{
      padding: 10px;
    height: 80%;
    margin: auto;
    width: 100%;
    }
    .swiper-Card{
      
      width: 75%; /* Kartların genişliği ayarlanabilir */
      height:100%; /* İstediğiniz yüksekliğe göre ayarlayabilirsiniz */ 
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 23px;
     
    }
    .brand-cardd {
      background-color: rgb(255, 255, 255);
      width: 75%; /* Kartların genişliği ayarlanabilir */
      height: 130px; /* İstediğiniz yüksekliğe göre ayarlayabilirsiniz */
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 23px;
      background-color: #00000014;
      box-shadow: 0px 7px 22px rgb(195 45 45 / 20%);
     
    }
    .swiper-wrapper {
    
      padding-left: 32px;
     
  }
  .tabs-content .swiper-wrapper {
    
    padding-left: 0;
  }
    .swiper-slide {
      flex-shrink: 0;
    
    }
  
    
    .brand-card img {
      width: 50%;
      height: auto;
      object-fit: cover;
      
    }

.swiper-button-next,
.swiper-button-prev {
  color: #000 !important;
  background-color: rgba(128, 122, 122, 0.5) !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 14px !important; /* İçerik (ikon) boyutunu ayarlayın */
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.tabs-content {
      margin: 40px 20px;
      border-radius: 10px;
      background-color: #F9F9F9;
    }

    
   
    
    .menu-cat-back {
      background: rgba(246, 17, 17, 0.17);
      width: 120px;
      height: 120px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 50%;
      color: rgb(255, 255, 255);
      padding: 7.5px;
      margin: auto;
      
    }
    .fa-solid, .fas {
      font-weight: 900;
      font-size:40px;
      display: inline-block;
      padding-left: 10px;
    
  }
  .swiper-slide .item{
  font-size: 30px;
  display: inline-block;
  font-family: 'Pacifico', cursive;
  padding-left: 10px;
  }
    .meun-cat {
      border-radius: 24px;
      background: rgb(255, 255, 255) !important;
      margin: 30px;
    }
    .meun-cat>div>div{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
    }
    .meun-cat>div>div>div:nth-of-type(2){
      margin:0 50px;
    }
    .ss{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
   .product-card {
    width: 45%;
    height: 150px;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */

    /* Rover effect on hover */
    &:hover {
      transform: scale(1.05); /* Increase the size on hover */
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    } 
  }
  
  .product-card h1 {
    margin-left: 10px;
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .product-card p {
    margin-left: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .product-card h2 {
    margin-top: 30px;
    margin-left: 10px;
    font-size: 1.25rem;
    color: #e44d26;
  }
  
  .product-img {
   
    padding: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 140px;
    height: 110px;
    border-radius: 20px;
    object-fit: cover;
  
    
  }
  .center-img{
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 767px) {


  .menu-section {
      background-color: #00000014;
  }
  .brand-card {
    width: 100%;
    height: 90px;
  }
 
  .brand-cardd {
    height: 100%;
    width:50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    background-color: #0000;
     box-shadow: 0px 7px 22px rgba(195, 45, 45, 0); 
}
.brand-card img {
  width: 90%;
}
.swiper-wrapper {
   padding-left: 10px; 
}
.swiper-Card {
  width: 100%;
}
.swiper-button-next, .swiper-button-prev {
  top: 64% !important;
  color: #000 !important;
  background-color: rgba(128, 122, 122, 0.5) !important;
  width: 20px !important;
  height: 20px !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {right: 3px !important;}
.swiper-button-prev, .swiper-rtl .swiper-button-next {left: 3px !important;}
.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 10px !important;
}
.tabs-content .title{
  background-color: #fff;
}
    /*prodect card*/
    .ss{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .product-card {
      width: 90%;
      height: 150px;
      padding: 5px;
      margin: 5px;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;
      transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */
  
      /* Rover effect on hover */
      &:hover {
        transform: scale(1.05); /* Increase the size on hover */
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
      } 
    }
    .product-card h1 {
      margin-left: 10px;
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .product-card p {
      margin-left: 10px;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .product-card h2 {
      margin-top: 30px;
      margin-left: 10px;
      font-size: 1.3rem;
      color: #e44d26;
    }
    .product-img {
   margin-right: 5px;
      padding: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 120px;
      height: 100px;
      border-radius: 20px;
      object-fit: cover;
    
      
    }

    /*prodect card*/


    /* categorybar*/
    .tabs-content {
      margin: 20px 10px;
      border-radius: 10px;
      background-color: #ffffff;
    }
    
    .menu-cat-back {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      color: rgb(255, 255, 255);
      padding: 7.5px;
    }
    
    .meun-cat {
      border-radius: 24px;
      background: rgba(246, 17, 17, 0.17);
      margin: 20px;
    }
    .fa-solid, .fas {
      
      font-size: 24px;
     
      padding-left: 1px;
  }
  .swiper-slide .item {
    font-size: 20px;
    padding-left: 10px;
}
    .meun-cat>div>div {
      display: flex;
      justify-content: space-around;
      padding: 10px;
      border-radius: 5px;
      align-items: center;
      margin: 10px; /* Adjusted margin for better spacing */
      background-color: #00000014;
    }
    
    .meun-cat>div>div>div:nth-of-type(2) {
      margin: 0 10px; /* Adjusted margin for better spacing */
    }
  }